/*  src/page/about.css  */

.about {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 2em;
    padding-bottom: 1em;
    margin-bottom: 4em;
    color: #FFF;
}

.about h1 {
    padding-top: 1em;
    padding-bottom: 1em;
    text-align: center;
}
