/*  src/page/Header.css  */

.Header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-bottom: 4em;
    background-color: #313335;
    color: #FFF;
}
