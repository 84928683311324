/*  src/page/Footer.css  */

.Footer {
    background-color: #313335;
    text-align: center;
    position: fixed;
    left: 0;
    bottom: 0;
    /*padding: 20px;*/
    /*height: 60px;*/
    padding-top: 1em;
    padding-bottom: 1em;
    /*margin-bottom: 4em;*/
    width: 100%;

    /*box-sizing: border-box;*/
    /*width: 100%;*/
    /*!*display: flex;*!*/
    /*justify-content: center;*/
    /*position: fixed;*/
    /*align-items: baseline;*/
    /*padding: 2em;*/
    /*margin-bottom: 4em;*/
    /*background-color: rgb(105,105,105);*/
    /*color: #FFF;*/
}

.Footer > div:nth-child(1) {
    color: #808080;
}

.Footer > div:nth-child(1) > a {
    color: #828282;
    text-decoration: none;
}

.Footer > div:nth-child(1) > a:hover {
    color: #FFFFFF;
}

.phantom {
    display: block;
    /*padding: 20px;*/
    /*height: 60px;*/
    padding-top: 1em;
    padding-bottom: 1em;
    /*margin-bottom: 4em;*/
    width: 100%;
}
