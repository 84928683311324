* {
    margin: 0;
    padding: 0;
}

.title {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: baseline;
    /*padding: 1em;*/
    margin-bottom: 1em;
    /*background-color: rgb(105,105,105);*/
    color: #FFF;
    font-size: 1.5em;
}

.bold {
    font-weight: bold;
}

.container {
    display: grid;
    height: calc(33vw - 3px);  /*instead playing around with grid gap gap */
    grid-template-columns: 1fr 1fr 1fr;
}

.container div:nth-child(2) {
    /* bg to show i'm squarred or not ? */
    /*background-image: linear-gradient( 45deg, transparent 50%, rgba(0, 0, 0, 0.5) 50%);*/

    margin: 0 5px 5px 0;  /*instead playing around with grid gap gap */
    /*background-color: red;*/
}


/* extra for demo, not needed */

.container {
    counter-reset: test;
}

.container div {
    display: flex;  /* or grid */
}

.container div:before {
    /*counter-increment: test;*/
    /*content: 'Div N°:' counter(test) ' -';*/
    margin: auto; /* center me */
    color: yellow;
}


.button {
    color: #272727;
    text-decoration: none;
    background-color: #737a81;
    border: 2px solid #737a81;
    padding: 15px 32px;
    text-align: center;
    display: inline-block;
    font-size: 16px;
    border-radius: 4px;
}

.button:hover {
    color: #efbe59;
    background-color: #272727;
}

.colorLink {
    color: #FFFFFF;
    text-decoration: none;
}

.colorLink:hover {
    color: #828282;
}


